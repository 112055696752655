.connector {
  .connectorDetailsWrapper {
    .connectorIsWithCable {
      img {
        margin-top: -5px;
        margin-left: -8px;
      }

      margin-left: 10px;
      margin-right: 5px;
      flex: 1;
      display: flex;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
