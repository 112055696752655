@media screen and (max-width: 600px) {
  .legendIcon {
    height: 25px;
    top: 100px;
    right: 15px;
  }
}

@media screen and (min-width: 601px) {
  .legendIcon {
    height: 25px;
    top: 30px;
    right: 20px;
  }
}

.legendIcon {
  position: fixed;
  z-index: 1;
  cursor: pointer;
  border-radius: 100%;
  padding: 10px;
}
