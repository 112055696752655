@media screen and (max-width: 600px) {
  .autoCompleteIcon {
    position: fixed;
    top: 25px;
    z-index: 1;
    right: 10px;
    cursor: pointer;
  }
}

@media screen and (min-width: 601px) {
  .autoCompleteIcon {
    display: none;
  }
}
