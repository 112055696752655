.connectorDetailsWrapper {
  .connectorTitleWithPower {
    img {
      margin-bottom: -5px;
      margin-right: 5px;
    }

    margin-left: 9px;
    margin-right: 5px;
    margin-bottom: 5px;
    flex: 1;
    justify-content: space-between;
    display: flex;
    font-weight: 700;
    font-size: 16px;
  }
}
