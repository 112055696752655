.mobilitiButton {
  padding: 10px 20px;
  box-shadow: 0 2px 5px rgba(42, 45, 52, 0.1);
  border-radius: 8px;
  outline: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;

  img {
    margin-bottom: -3px;
    margin-right: 10px;
  }

  &.dark {
    &.mobilitiGreen {
      color: black;
      background: #52d0cf;
      border: none;
    }

    &:not(.mobilitiGreen) {
      color: #8b9cb2;
      background: black;
      border: 1px solid #393939;

      &:hover {
        background: #353535;
        transition: 0.2s ease-in-out;
      }

      &.active {
        background: #393939;
        color: white;
      }
    }
  }

  &.light {
    &.mobilitiGreen {
      color: white;
      background: #408e8d;
      border: none;
    }

    &:not(.mobilitiGreen) {
      color: #183254;
      background: #ffffff;
      border: 0.5px solid #ecf2f8;

      &:hover {
        background: #f8f8f8;
        transition: 0.2s ease-in-out;
      }

      &.active {
        background: #183254;
        color: white;
      }
    }
  }
}
