.legendItem {
  margin: 5px 0;
  display: inline-block;

  div {
    line-height: 20px;
    margin-left: 15px;
    display: inline-block;
  }

  .legendItemHeader {
    font-weight: 500;
    font-size: 16px;
  }

  .legendItemSubHeader {
    color: rgba(139, 156, 178, 1) !important;
    font-size: 14px;
  }
}
