.filterTypeWrapper {
  margin: 20px 0;

  .filterType {
    cursor: pointer;
    margin: 8px 0;
    padding: 8px;
    border-radius: 8px;

    img {
      margin-bottom: -3px;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    div.label {
      font-weight: 500;
      display: inline-block;
      vertical-align: super;
    }

    div.disabled {
      color: rgba(139, 156, 178, 1) !important;
    }

    .toggle {
      float: right;
    }

    .arrow {
      float: right;
      transform: translateY(150%);
      height: 8px;
    }
  }
}
