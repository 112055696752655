.LocationDetailsModal {
  .connectors {
    &.dark {
      .connector {
        border: 1px solid rgb(56, 56, 56);

        &-available {
          .connectorImage {
            background-color: #34a700;
          }
        }

        &-charging {
          .connectorImage {
            background-color: #0072bb;
          }
        }
      }
    }

    &.light {
      .connector {
        border: 1px solid #e5edfc;

        &-available {
          .connectorImage {
            background-color: #34a700;
          }
        }

        &-charging {
          .connectorImage {
            background-color: #0072bb;
          }
        }
      }
    }

    margin-bottom: 20px;

    .connector {
      margin-bottom: 10px;
      padding: 5px;
      border-radius: 8px;
      display: flex;
      flex-flow: wrap;

      .connectorDetailsWrapper {
        flex: 1;
        margin-top: 10px;
      }
    }
  }
}
