.LocationDetailsModal {
  .comments-likes-navigation-wrapper {
    .navigation-indicator {
      margin-top: 5px;
      display: flex;
      cursor: pointer;
      border-radius: 12px;
      text-align: center;
      padding: 8px 12px 0px 12px;
      height: 33px;
      font-weight: 600;

      img {
        height: 24px;
        margin-top: 2px;
        margin-right: 10px;
      }

      div {
        padding-left: 5px;
      }
    }
  }
}

.navigationPopup {
  font-weight: 600;
  padding: 10px 5px 5px 5px;
  text-align: center;

  .navigationType {
    display: inline-block;
    font-size: 12px;
  }

  .navigationWrapper {
    margin: 0 auto;
    padding: 10px;
    text-align: center;

    img {
      cursor: pointer;
      padding: 5px;
      border-radius: 6px;
    }
  }
}
