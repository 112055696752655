@import "~react-redux-toastr/src/styles/index";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: rgba(47, 128, 237, 1) !important;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(139, 156, 178, 0);
}

::-webkit-scrollbar-thumb {
  background: rgba(139, 156, 178, 1);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.googleMap {
  &.blurred {
    pointer-events: none;
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
  }
}

#mobiliti-popup {
  &-dark {
    .rrt-confirm {
      background-color: #1c1c1e;
      color: white;

      button:active,
      button:hover {
        background-color: #408e8d;
      }

      button {
        color: white;
      }
    }
  }

  &-light {
    .rrt-confirm {
      .rrt-buttons-holder {
        background-color: white;

        button:active {
          background-color: #408e8d;
        }
      }
    }
  }
}

.toastr {
  font-size: 16px;
  font-weight: 500;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);

  &.rrt-success {
    background-color: #408e8d !important;
  }

  &.rrt-info {
    background-color: #408e8d !important;
  }
}

.gm-iv-container,
.gm-iv-address {
  margin-top: 45px !important;
}

.gm-iv-address-description div {
  margin-top: 30px !important;
}

.gm-iv-marker {
  top: 49px !important;
}

.gm-fullscreen-control {
  display: none;
}
