.LocationDetailsModal {
  .carousel {
    border-radius: 8px;

    .slider-wrapper {
      height: 250px !important;
    }

    .slide {
      img {
        height: 250px !important;
        object-fit: contain !important;
      }
    }
  }

  .comments-likes-navigation-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 18px !important;
  }

  .description {
    color: rgba(139, 156, 178, 1) !important;
    font-size: 14px;
  }

  .address {
    * {
      color: rgba(139, 156, 178, 1) !important;
      font-size: 14px;
    }
  }
}
