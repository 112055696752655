@mixin animationCancel() {
  &::after {
    border-radius: 50%;
    transform: rotate(0) !important;
    background-color: white;
    width: 3.5px !important;
    height: 3.5px !important;
    left: 3.5px !important;
    top: 4.5px !important;
    animation-timing-function: initial !important;
    animation-duration: 0s !important;
    animation-name: none !important;
  }
}

.rc-checkbox-checked {
  .rc-checkbox-inner {
    background-color: #408e8d !important;
    border-color: rgb(136, 136, 136) !important;
    border-radius: 50%;

    @include animationCancel;
  }
}

.rc-checkbox .rc-checkbox-inner {
  border-radius: 50%;

  @include animationCancel;
}

.country {
  * {
    display: inline-block;
  }

  .countryLabel {
    margin-left: 20px;
  }
}
