.mobilitiToggleWrapper {
  &.toggle-on {
    .react-switch-handle {
      background-image: url("../../../assets/img/lightning_yellow.svg") !important;
      background-position: center !important; /* Center the image */
      background-repeat: no-repeat !important; /* Do not repeat the image */
      background-size: 8px !important; /* Resize the background image to cover the entire container */
    }
  }

  &.toggle-off {
    .react-switch-handle {
      background-image: url("../../../assets/img/lightning_gray.svg") !important;
      background-position: center !important; /* Center the image */
      background-repeat: no-repeat !important; /* Do not repeat the image */
      background-size: 8px !important; /* Resize the background image to cover the entire container */
    }
  }
}
