@media screen and (max-width: 600px) {
  .mobilitiModeIcon {
    bottom: 38px;
    left: 17px;
  }
}

@media screen and (min-width: 601px) {
  .mobilitiModeIcon {
    top: 150px;
    right: 0;
  }
}

.mobilitiModeIcon {
  position: fixed;
  z-index: 1;
  cursor: pointer;
  margin-right: 15px;
  margin-top: 18px;
  border-radius: 50px;
  padding: 8px 12px;
}
