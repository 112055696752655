h1 {
  margin-top: 0;
  font-size: 22px;
}

hr {
  border-top: 1px solid #e5e5e5;
  border-bottom: none;
  border-left: none;
  border-right: none;
}

h2 {
  font-size: 18px;
  font-weight: 700;
}

.sectionHint {
  font-size: 14px;
  font-weight: 600;
  color: #fd6464; // simple red is not readable in dark mode
}
