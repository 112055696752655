.LocationDetailsModal {
  .location-detail-items {
    &.dark {
      .location-detail-item {
        border: 1px solid rgb(56, 56, 56);
      }
    }

    &.light {
      .location-detail-item {
        border: 1px solid #e5edfc;
      }
    }

    .location-detail-item {
      text-align: right;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 7px;

      img {
        height: 30px;
      }

      div {
        display: flex;

        .text-left {
          margin-left: 10px;
          margin-top: 4px;
        }

        .text-right {
          margin-top: 4px;
        }
      }
    }
  }
}
