.themeSwitcherWrapper {
  margin: 20px 0 20px 0;

  button {
    &:not(last-of-type) {
      margin-right: 5px;
    }
  }

  .timeBasedThemeToggler {
    margin-top: 10px;

    input[type="time"] {
      color: #2a2c2d;
      font-size: 14px;
      font-family: helvetica, serif;
      padding: 5px;
      border: 1px solid #e5edfc;
      border-radius: 8px;
      outline: none;

      &:not(last-of-type) {
        margin-right: 5px;
      }

      &:last-of-type {
        margin-left: 5px;
      }
    }
  }

  &-dark {
    .timeBasedThemeToggler {
      input[type="time"] {
        background-color: #393939;
        color: white;
        border: none;
      }

      input[type="time"]::-webkit-calendar-picker-indicator {
        background-color: gray;
        border-radius: 10px;
      }
    }
  }

  &-light {
  }
}
