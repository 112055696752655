.mobilitiFooterTitle {
  font-weight: bold;
  height: 20px;
  font-size: 14px;
  position: fixed;
  bottom: 33px;
  left: 52px;

  span {
    margin-right: 5px;
  }

  img {
    height: 11px;
  }
}

.mobilitiFooterSubtitle {
  position: fixed;
  font-size: 12px;
  color: #8b9cb2;
  bottom: 16px;
  left: 52px;
}
