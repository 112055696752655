.connector {
  .connectorImage {
    &-available {
      &.dark {
        div {
          color: white;
        }
      }

      &.light {
        div {
          color: white;
        }
      }
    }

    &-charging {
      &.dark {
        div {
          color: white;
        }
      }

      &.light {
        div {
          color: white;
        }
      }
    }

    padding: 10px 3px 10px 0;
    text-align: center;
    width: 45px;
    border-radius: 8px;

    img {
      width: 45px;
      margin: auto;
      padding-left: 4px;
    }

    div {
      font-size: 20px;
      padding-bottom: 15px;
      font-weight: bold;
    }
  }
}
