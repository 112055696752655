.LocationDetailsModal {
  .comments-likes-navigation-wrapper {
    .comments {
      cursor: pointer;
      margin-top: 5px;
      margin-right: 5px;
      display: flex;
      border-radius: 8px;
      padding: 10px 15px 10px 15px;

      img {
        margin-right: 10px;
        height: 18px;
      }

      .comments-counter {
        margin-top: -3px;
      }

      &.light {
        background: #ffffff;
        border: 1px solid #e5edfc;
      }

      &.dark {
        border: 1px solid rgb(56, 56, 56);
      }
    }
  }
}
