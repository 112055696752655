@media screen and (max-width: 600px) {
  .autocompleteResults {
    margin-top: 70px;

    .autocompleteResult {
      text-align: left;
      cursor: pointer;
      margin: 5px 0;

      img {
        display: inline-block;
        height: 20px;
        padding: 10px 15px 5px 5px;
      }
    }
  }
}

@media screen and (min-width: 601px) {
  .autocompleteResults {
    margin-top: 70px;

    .autocompleteResult {
      text-align: left;
      cursor: pointer;
      margin: 5px 0;

      img {
        display: inline-block;
        height: 20px;
        padding: 10px 15px 5px 5px;
      }
    }
  }
}
