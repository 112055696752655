@media screen and (max-width: 600px) {
  .snowToggleIcon {
    height: 50px;
    bottom: 180px;
    left: 8px;
  }
}

@media screen and (min-width: 601px) {
  .snowToggleIcon {
    height: 45px;
    top: 295px;
    right: 11px;
  }
}

.snowToggleIcon {
  position: fixed;
  z-index: 1;
  cursor: pointer;
  border-radius: 100%;
  padding: 10px;
}
