.LocationDetailsModal {
  .comments-likes-navigation-wrapper {
    .likes {
      display: flex;
      margin-top: 5px;

      &.light {
        img {
          background: #ffffff;
        }

        .like-indicator,
        .dislike-indicator {
          border: 1px solid #e5edfc;
        }
      }

      &.dark {
        img {
          background: black;
        }

        .like-indicator,
        .dislike-indicator {
          border: 1px solid rgb(56, 56, 56);
        }
      }

      .like-indicator {
        cursor: pointer;
        padding-right: 5px;
        margin-right: 10px;
        display: inline-flex;
        border-radius: 8px;

        img {
          height: 18px;
          padding: 10px 0 10px 10px;
          margin-right: 7px;
        }
      }

      .dislike-indicator {
        cursor: pointer;
        margin-right: 10px;
        padding-left: 5px;
        display: inline-flex;
        border-radius: 8px;

        img {
          height: 18px;
          padding: 10px 10px 10px 0;
          margin-left: 7px;
        }
      }

      .like-counter {
        margin-top: 7px;
      }
    }
  }
}
