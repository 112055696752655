@media screen and (max-width: 600px) {
  .closeIcon {
    top: 78px;
    right: 25px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
  }

  .magnifyingGlassForInputField {
    top: 127px;
    right: 40px;
    width: 18px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
  }

  .closeIconForInputField {
    top: 127px;
    right: 40px;
    width: 15px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
  }

  .searchBox {
    padding: 50px 10px 20px 10px;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0px -4px 20px rgba(35, 35, 35, 0.2);
    border-radius: 8px;
  }

  .searchInput {
    background-color: rgb(240, 241, 242);
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 94vw;
    height: 40px;
    padding: 0 50px 0 12px;
    background-size: 20px 20px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    position: absolute;
    left: 0;
    margin-top: 0;
    margin-left: 10px;

    &::placeholder {
      color: #8b9cb2 !important;
    }
  }

  .searchBox p {
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
  }
}

@media screen and (min-width: 601px) {
  .closeIcon {
    display: none;
  }

  .closeIconForInputField {
    top: 70px;
    right: 28px;
    cursor: pointer;
    width: 15px;
    position: absolute;
    z-index: 1;
  }

  .magnifyingGlassForInputField {
    top: 68px;
    width: 18px;
    right: 25px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
  }

  .searchBox {
    padding: 10px;
    text-align: center;
    position: absolute;
    width: 350px;
    min-height: 97px;
    left: 18px;
    top: 40px;
    box-shadow: 0px -4px 20px rgba(35, 35, 35, 0.2);
    border-radius: 8px;
  }

  .searchInput {
    background-color: rgb(240, 241, 242);
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 350px;
    height: 40px;
    padding: 0 50px 0 12px;
    background-size: 20px 20px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    position: absolute;
    left: 0px;
    margin-top: 0px;
    margin-left: 10px;

    &::placeholder {
      color: #8b9cb2 !important;
    }
  }

  .searchBox p {
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
  }
}
