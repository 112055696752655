@media screen and (max-width: 500px) {
  .mobilitiModal {
    top: 0;
    width: 100vw;
    max-height: 100vh;
    right: 0;
  }
}

@media screen and (min-width: 500px) {
  .mobilitiModal {
    top: 20px;
    width: 470px;
    max-height: 95vh;
    right: 1%;
  }
}

.mobilitiModal {
  position: absolute;
  z-index: 500;
  min-width: 25%;
  overflow-x: auto;
  box-shadow: 0px -4px 20px rgba(35, 35, 35, 0.2);
  border-radius: 8px;
  box-sizing: border-box;
  transition: opacity 0.2s ease-in-out;

  .mobilitiModalContent {
    padding: 15px 15px 10px 15px;
  }
}
