@media screen and (max-width: 600px) {
  .languageToggleIcon {
    height: 50px;
    bottom: 120px;
    left: 20px;
  }
}

@media screen and (min-width: 601px) {
  .languageToggleIcon {
    height: 45px;
    top: 240px;
    right: 21px;
  }
}

.languageToggleIcon {
  position: fixed;
  z-index: 1;
  cursor: pointer;
  border-radius: 100%;
  padding: 5px;
  height: 35px;
}
