@media screen and (max-width: 600px) {
  .filterIcon {
    bottom: 20px;
    right: 0;
  }
}

@media screen and (min-width: 601px) {
  .filterIcon {
    top: 85px;
    right: 0;
  }
}

.filterIcon {
  position: fixed;
  z-index: 1;
  cursor: pointer;
}
