.sliderWrapper {
  padding: 0 25px 10px 10px;
  margin: 30px 0;

  &.dark {
    .rc-slider-mark-text-active {
      color: white;
    }
  }

  &.light {
    .rc-slider-mark-text-active {
      color: black;
    }
  }
}

.rc-slider {
  margin: 20px 5px !important;
  padding: 5px;
  width: initial;
}

.rc-slider-handle {
  position: absolute;
  width: 35px !important;
  height: 35px !important;
  cursor: pointer;
  margin-top: -15px !important;
  border-radius: 50%;
  margin-left: 0px;
  background-color: #fff;
  touch-action: pan-x;
  background-image: url(https://previews.123rf.com/images/zo3listic/zo3listic1805/zo3listic180500007/122377139-check-icon-vector-check-mark-icon-check-list-button-icon.jpg);
  background-size: cover;
  border: 1px solid #ecf2f8 !important;
  box-shadow: 0px 2px 5px rgba(42, 45, 52, 0.1) !important;
}

.rc-slider-step {
  display: none !important;
}

.rc-slider-rail {
  height: 8px !important;
}

.rc-slider-mark-text {
  margin-left: -0px !important;
  font-weight: 500;
}

.rc-slider-track {
  position: absolute !important;
  left: 0 !important;
  height: 8px !important;
  border-radius: 20px !important;
  background-color: #ffc20e !important;
}

.rc-slider-mark {
  position: absolute !important;
  top: 30px !important;
  left: 0 !important;
  width: 100%;
  font-size: 14px !important;
}
