.clusteringToggleWrapper {
  margin-top: 10px;
  padding: 8px 0;

  .clusteringSwitch {
    float: right;
  }

  div.label {
    font-weight: 500;
    display: inline-block;
    vertical-align: super;
  }
}
