#appleStoreBadge {
  margin: 5px 10px 0px 5px;
  cursor: pointer;
  height: 32px;
}

#googlePlayBadge {
  margin: 5px 5px 0px 15px;
  cursor: pointer;
  height: 32px;
}
