.LocationDetailsModal {
  .title {
    margin-bottom: 20px;

    .label {
      font-weight: 700;
      font-size: 20px;
      margin-right: 10px;
      color: rgba(94, 135, 252, 1);
    }

    .main-title {
      font-weight: 700;
      font-size: 20px;
    }

    .operator {
      font-size: 16px;
    }

    .address {
      color: rgba(139, 156, 178, 1) !important;
      font-size: 13px;
    }
  }
}
