@media screen and (max-width: 600px) {
  .footer {
    display: none;
  }
}

.footer {
  z-index: 1;
  height: 60px;
  bottom: 0;
  position: fixed;
  width: 100%;
  padding: 0;
}
