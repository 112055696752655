@media screen and (max-width: 600px) {
  .googleMap {
    height: 100vh;
  }
}

@media screen and (min-width: 601px) {
  .googleMap {
    height: calc(100vh - 60px);
  }
}

.googleMap {
  position: fixed !important;
  width: 100%;
}

.cluster {
  margin-left: 15px;

  img {
    height: 60px;
    content: url("../../assets/img/clusterer/pin1.svg");

    &[src*="m4"] {
      + div {
        color: white !important;
        left: -15px !important;
        top: -17px !important;
      }
    }

    &[src*="m3"] {
      + div {
        color: white !important;
        left: -9px !important;
        top: -11px !important;
      }
    }

    &[src*="m2"] {
      + div {
        color: white !important;
        left: -4px !important;
        top: -7px !important;
      }
    }

    &[src*="m5"] {
      + div {
        color: white !important;
        left: -20px !important;
        top: -25px !important;
      }
    }

    &[src*="m1"] {
      + div {
        color: white !important;
        left: -9px !important;
        top: -11px !important;
      }
    }
  }
}
