.chargerTypeWrapper {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: 300px) {
    .chargerTypeIcon {
      height: 18vw;
      width: 18vw;
    }

    .chargerTypeIcon {
      margin: 3px;
      cursor: pointer;
      border-radius: 12px;
      padding: 5px;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 600px) {
    .chargerTypeIcon {
      height: 18vmin;
      width: 18vmin;
      flex-basis: 20%;
    }

    .chargerTypeIcon {
      margin: 3px;
      cursor: pointer;
      border-radius: 12px;
      padding: 12px;
    }
  }

  @media screen and (min-width: 600px) {
    .chargerTypeIcon {
      height: 90px;
      width: 90px;
    }

    .chargerTypeIcon {
      margin: 3px;
      cursor: pointer;
      border-radius: 12px;
      padding: 12px;
    }
  }
}
